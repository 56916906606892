const carbody = {
  brand: 'Marca',
  car: 'Vettura',
  description: 'Descrizione',
  supplier_code: 'Codice fornitore',
  cati_code: 'Codice CATI',
  price: 'Prezzo',
  OE: 'Codice OE',
  mt3: 'MT3',
  weight: 'Peso lordo',
  ean: 'Codice EAN',
  certification: 'Certificazione',
  upload_missed:
    'Alcuni prezzi inseriti non hanno un codice prodotto corrispondente',
  product_id: 'ID Prodotto',
  name: 'Nome',
  supplier_label: 'Fornitore',
  created_at: 'Data inserimento',
  previous_price: 'Il prezzo precedente è {value}',
  date_from_hint: 'Aggiungi data da',
  date_to_hint: 'Aggiungi data a',
  date_error: 'Il campo data da deve essere valorizzato!',
  supplier_products: 'Prodotti Fornitori',
  cars: 'Autovetture',
  class_merch: 'Scegli la classe merceologica',
  total: 'Totale',
  insert_date: 'Data inserimento',
  height: 'Altezza',
  length: 'Lunghezza',
  width: 'Larghezza',
  clear_orders: 'Cancella ordini',
  confirm_clear_orders:
    'Gli ordini non sono stati cancellati, vuoi proseguire?',
  available: 'Nascondi non disponibili',
  categories_add_tag: 'Aggiungi la categoria prodotto',
  categories_hint: 'Categoria prodotto',
  carbody_new_product_title: 'Crea nuovo prodotto carrozzeria',
  carbody_edit_product_title: 'Modifica cod. CATI',
  cati_code_hint: 'Aggiungi Codice CATI',
  steps: {
    general: 'Generale',
    save: 'Salva',
    cars: 'Autovetture',
    description: 'Descrizione',
    product_details: 'Dettagli prodotto',
    warehouses: 'Magazzini inattivi',
  },
  select_car_title: 'Seleziona auto compatibili',
  description_label: 'Descrizione prodotto',
  description_hint: 'Aggiungi descrizione prodotto',
  active: 'Prodotto attivo',
  active_label: 'Seleziona codice CATI attivo/disattivo',
  product_available: 'Seleziona prodotto attivo/disattivo',
  oem_hint: 'OEM prodotto',
  oem_label: 'Aggiungi OEM prodotto',
  ean_hint: 'EAN prodotto',
  ean_label: 'Aggiungi EAN prodotto',
  price_hint: 'Prezzo prodotto',
  price_label: 'Aggiungi prezzo prodotto',
  class: 'Classe merceologica',
  supplier_id: 'Fornitore',
  brands: 'Marca auto',
  models: 'Modello auto',
  OEM: 'OEM',
  EAN: 'EAN',
  category: 'Categoria prodotto',
  create_new: 'Crea nuovo modello',
  supplier_product_code: 'Codice prodotto del fornitore',
  supplier_product_code_hint: 'Aggiungi il codice prodotto del fornitore',
  submit_product: 'Salva prodotto',
  export_products: 'Esporta',
  new_codes: 'nuovi prodotti',
  new_code: 'nuovo prodotto',
  cati_code_search: 'Cerca per codice CATI o Codice prodotto fornitore',
  cati_code_edit_page: 'Codice CATI o Codice prodotto fornitore',
  id: 'ID',
  save_new_product: 'Salva codice',
  new_product_saved: 'Salvato codice',
  products_with_code: 'Prodotti con codice',
  back_to_first_step: 'Torna alla pagina iniziale',
  minumum_margin: 'Margine minimo',
  margin: 'Margine',
  go_back_to_order_bot: "Torna all'OrderBot",
  mpc: 'MPC',
  diff: 'Diff.',
  target_price: 'Target price',
  supplier_product_available: 'Prodotto fornitore disponibile',
  upload_message_1: 'Il lavoro di caricamento {batch_id} é in corso.',
  upload_message_2:'Appena sarà terminato una mail riepilogativa sarà inviata a {user_email} con allegato un files dei prodotti che non hanno un codice CATI.',
  inactive_warehouses: 'Magazzini inattivi',
  proceed_anyway: 'Procedi comunque',
  valid_from_hint: 'Prezzo valido dal (se vuoto, prezzo valido dalla data corrente!)',
  oem_alert: 'OEM non univoco!',
  oem_block_title: 'Gli OEM associati al prodotto sono già presenti:',
  oem_block_supplier: 'Fornitore:',
  oem_block_product: 'Prodotto:',
  oem_block_equivalent_codes: 'Codici OEM equivalenti:',
  is_complete_catalogue: 'Catalogo completo',
}

export default carbody
